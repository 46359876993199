<template>
  <ion-content class="">
    <div class="flex items-center h-full">
      <div class="flex-1">
        <div class="ion-text-center flex justify-center mb-5">
          <router-link :to="{ name: 'news' }">
            <ion-img src="/img/logo.svg" class="logo" />
          </router-link>
        </div>

        <form @submit.prevent="ignoreDefaultFormSubmit">
          <div class="ion-padding" v-show="!success">
            <ion-item v-if="step == 1">
              <ion-label position="stacked" text-wrap>
                {{ $t('requestpasswordresetlabel') }}
              </ion-label>
              <ion-input type="email" autocomplete="username" :placeholder="$t('email')" :value="email" @ionInput="email = $event.target.value" />
            </ion-item>
            <ion-item v-if="step == 2">
              <ion-label position="stacked" text-wrap>
                {{ $t('twofactorauthentication') }}
              </ion-label>
              <ion-input
                type="text"
                :placeholder="$t('twofactorauthentication')"
                :value="tfa_code"
                @ionInput="tfa_code = $event.target.value" />
            </ion-item>
          </div>

          <div class="ion-padding">
            <LoadingButton color="moto" :disabled="loading || success" :loading="loading" @click="enterEmail" v-if="step == 1">
              {{ $t('next') }}
            </LoadingButton>
            <LoadingButton color="moto" :disabled="loading || success" :loading="loading" @click="submitPasswordReset" v-else>
              {{ $t('requestpasswordreset') }}
            </LoadingButton>
            <ion-button type="button" expand="full" @click="$router.push({ name: $routeNames.LOGIN })">
              {{ $t('backtologin') }}
            </ion-button>

            <Error :error="error" />

            <div v-if="success">
              {{ $t('requestpasswordresetsuccess', { email }) }}
            </div>
          </div>
        </form>
      </div>
    </div>
  </ion-content>
</template>

<script>
import { FORGOT_PASSWORD } from "@/graphql/mutations";
import { showToast } from "@/utils/helpers";
import { HAS_TFA_ENABLED_ACTION } from "@/store/store-actions";
export default {
  data() {
    return {
      step: 1,
      email: '',
      error: null,
      loading: false,
      success: false,
      hasTfa: false,
      tfa_code: ''
    }
  },
  methods: {
    showToast,
    ignoreDefaultFormSubmit() {
      return false;
    },
    async enterEmail() {
      this.error = null;
      this.loading = true;
      try {
        this.hasTfa = await this.$store.dispatch(HAS_TFA_ENABLED_ACTION, {email: this.email});
      } catch (e) {
        this.error = e;
        return;
      } finally {
        this.loading = false;
      }

      if (this.hasTfa) {
        this.step = 2;
      } else {
        return this.submitPasswordReset();
      }
    },
    async submitPasswordReset() {
      this.error = null;
      this.success = false;
      this.loading = true;

      try {
        await this.$apollo.mutate({
          mutation: FORGOT_PASSWORD,
          variables: {
            email: this.email,
            tfa_code:this.tfa_code,
          }
        });

        this.success = true;
        this.showToast( this.$t('requestpasswordresetsuccess', { email: this.email }), 'success', 6000 )
      } catch (error) {
        this.error = error;
      }

      this.loading = false;
    },
  },
};
</script>
